'use client'
import LoginButton from '@/components/home/loginButton'
import ROUTES from '@/lib/constants/ROUTES'
import { cn } from '@/lib/utils'
import { signIn, useSession } from 'next-auth/react'
import { useRouter } from 'next/navigation'
import { useEffect, useState } from 'react'

export function LoginPage() {
  const [loading, setLoading] = useState(false)
  const router = useRouter()
  const { data: session } = useSession()

  useEffect(() => {
    if (session) {
      router.replace(ROUTES.DashboardPage)
    }
  }, [router, session])

  return (
    <>
      <section className="mx-auto my-24 min-h-[50vh] max-w-6xl px-6 pt-12 ">
        <div className="flex flex-col items-center justify-center">
          <div
            className={cn(
              'mb-24 max-w-md font-black text-purple-900',
              'text-base sm:text-2xl'
            )}
          >
            Log in to {process.env.NEXT_PUBLIC_DOMAIN_NAME}
          </div>

          {!session && (
            <LoginButton
              loading={loading}
              onClick={() => {
                setLoading(true)
                signIn(
                  'google',
                  {
                    callbackUrl: `${window.location.origin}${ROUTES.DashboardPage}`,
                  },
                  { prompt: 'login' }
                )
              }}
            />
          )}
        </div>
      </section>
    </>
  )
}
