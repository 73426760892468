import { cn } from '@/lib/utils'
import { noop } from '@/lib/utils/noop'
import { ReactNode } from 'react'

export interface ButtonProps {
  children?: ReactNode | undefined
  className?: string
  category?: 'primary' | 'secondary' | 'mega'
  onClick?: () => void
  type?: 'submit' | 'reset' | 'button'
  disabled?: boolean
}
const Button: React.FC<ButtonProps> = ({
  className = '',
  onClick = noop,
  category = 'primary',
  type = 'button',
  disabled = false,
  ...props
}) => {
  switch (category) {
    case 'mega':
      return (
        <button
          disabled={disabled}
          className={cn(
            `px-7 py-2 shadow-mega active:shadow-megaActive`,
            `bg-yellow-500 hover:bg-yellow-mega-hover active:bg-yellow-mega-active`,
            `rounded-lg border-purple-900 outline-none focus:outline-none `,
            `font-black text-purple-900 `,
            className
          )}
          type={type}
          style={{
            borderWidth: 3,
            transition: 'all .15s ease',
          }}
          onClick={onClick}
        >
          {props.children}
        </button>
      )
    case 'primary':
      return (
        <button
          disabled={disabled}
          className={cn(
            'h-[38px] rounded-lg bg-purple-900 px-4 text-center text-base font-bold text-white outline-none hover:text-gray-200 focus:outline-none',
            disabled ? 'cursor-not-allowed opacity-50' : 'active:text-gray-400',
            className
          )}
          type={type}
          style={{ transition: 'all .15s ease' }}
          onClick={onClick}
        >
          {props.children}
        </button>
      )
    case 'secondary':
      return (
        <button
          disabled={disabled}
          className={cn(
            'h-[38px] rounded-lg border-2 border-purple-900 px-4 font-bold text-purple-900 outline-none focus:outline-none',
            disabled
              ? 'cursor-not-allowed opacity-50'
              : 'hover:bg-gray-200 active:bg-gray-400',
            className
          )}
          type={type}
          style={{ transition: 'all .15s ease' }}
          onClick={onClick}
        >
          {props.children}
        </button>
      )
  }
}

export default Button
