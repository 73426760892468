import styles from './loading-dots.module.css'

const LoadingDots = ({ dotClassName }: { dotClassName?: string }) => {
  return (
    <span className={styles.loading}>
      <span className={dotClassName} />
      <span className={dotClassName} />
      <span className={dotClassName} />
    </span>
  )
}

export default LoadingDots
